#root {
  background-color: rgba(43, 43, 43, 0);
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 90vh;
}

.bodyContainerOff {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.bodyContainerCentered {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
}

.routeName {

  color: rgb(230, 71, 103);
  position: relative;
}

.carrusel {
  position: static;
  width: 25vw;
  height: 35vh;

  margin-right: auto;
  margin-left: auto;


}

.carrusel img {
  padding: 3%;
  width: 25vw;
  height: 35vh;
  max-width: 100%;
  max-height: 100%;
  display: block;
  /* remove extra space below image */

}

.bodyTable {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}

#footerContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 10vh;
  background: rgba(4, 23, 105, 0.966);
  left: 0%;
  bottom: initial;
  margin-bottom: 0;

}

#footerContainer a {
  margin-top: 0.5%;
  margin-right: auto;
  margin-left: auto;
  color: white;
  font-weight: 300;
  align-items: center;
  text-align: center;
}


.active {
  background-color: rgb(39, 21, 90);
  color: rgb(247, 247, 247) !important;
  font-weight: 500 !important;
  border-radius: 4px;
  padding-left: 10px !important;
}

#root {
  background-color: rgb(138, 132, 132);
}

body {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

body::-webkit-scrollbar {
  width: 12px; /* ancho de la barra de desplazamiento */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* color del pulgar de la barra de desplazamiento */
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* color de la pista de la barra de desplazamiento */
}

div::-webkit-scrollbar {
  width: 0.1em;
}

div::-webkit-scrollbar-thumb {
  background-color: transparent;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Para navegadores Firefox */
div {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Para navegadores Microsoft Edge e Internet Explorer */
div {
  -ms-overflow-style: none;
}

div::-ms-scrollbar-thumb {
  background-color: transparent;
}

div::-ms-scrollbar-track {
  background-color: transparent;
}
